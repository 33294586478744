import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@mui/material"
import { useState } from "react"
import { Form, RaRecord, SaveButton, useTranslate } from "react-admin"
import { ScaleButton, CallToActionButton } from "./ScaleButtons"

interface ContentWrapperProps {
  active: boolean
  children?: JSX.Element | JSX.Element[] | null
}

const ContentWrapperCard = ({ active, children }: ContentWrapperProps) => {
  return (
    <Card style={{ minHeight: '12lh', height: '100%', opacity: active ? 1 : 0.5 }}>
      <div style={{ height: '100%', padding: '1em', display: 'flex', flexDirection: 'column' }}>
        {children}
      </div>
    </Card>
  )
}

export interface DisplayCardProps {
  translationPrefix: string
  active: boolean
  children?: JSX.Element | JSX.Element[] | null
}

export const DisplayCard: React.FC<DisplayCardProps> = ({
  translationPrefix,
  active,
  children,
}) => {
  const translate = useTranslate();
  const content = active ? children : null
  return (
    <ContentWrapperCard active={active}>
      <CardHeader title={translate(`${translationPrefix}.title`)} />
      <CardContent>{content}</CardContent>
    </ContentWrapperCard>
  )
}

type SaveClickHandler = (formData: Record<string, any>) => Promise<any> | void

export interface EditableWizardCardProps {
  translationPrefix: string
  step: number
  currentStep: number
  displayContent?: JSX.Element | null
  editRecord?: RaRecord
  editForm?: JSX.Element | null
  onEditCancel?: () => void
  onEditSave?: SaveClickHandler
  saveButtonTitle?: string
}

export const EditableWizardCard: React.FC<EditableWizardCardProps> = ({
  translationPrefix,
  step,
  currentStep,
  displayContent,
  editRecord,
  editForm,
  onEditCancel = () => { },
  onEditSave = async () => Promise.resolve(window.alert("save not implemented")),
  saveButtonTitle,
}) => {
  const translate = useTranslate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const isNextToBeFilled = step === currentStep;
  const isReadyForEdit = step < currentStep;

  const handleOpenDialog = () => {
    setIsDialogOpen(true)
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false)
  };

  const handleCancel = async () => {
    onEditCancel()
    handleCloseDialog()
  }

  const title = translate(`${translationPrefix}.title`)

  const headerAction = isReadyForEdit
    ? <ScaleButton
        variant='contained'
        size='small'
        color="primary"
        onClick={handleOpenDialog}
      >{translate(`${translationPrefix}.editText`)}</ScaleButton>
    : <Avatar
      sx={{
        height: 24,
        width: 24,
        backgroundColor: isNextToBeFilled ? '#FF5F6D' : '#253241',
        fontSize: '12px',
        fontWeight: 'bold',
      }}>{step}</Avatar>

  const editDialog = (<EditDialog
    open={isDialogOpen}
    onClose={handleCloseDialog}
    onEditSave={onEditSave}
    title={title}
    editRecord={editRecord}
    editForm={editForm}
    onEditCancel={onEditCancel}
    saveButtonTitle={saveButtonTitle}
  />);

  const firstTimeContent = <div>
    <Typography gutterBottom variant="body1">
      {translate(`${translationPrefix}.instruction`)}
    </Typography>
    {editDialog}
  </div>

  const content = isReadyForEdit
    ? displayContent
    : isNextToBeFilled
      ? firstTimeContent
      : null

  return (
    <ContentWrapperCard active={currentStep >= step}>
      <CardHeader title={title} action={headerAction} />
      <CardContent>{content}</CardContent>
      {isNextToBeFilled
        ? <CardActions sx={{ flexGrow: 1 }}>
            <CallToActionButton
              variant="contained"
              color="primary"
              onClick={handleOpenDialog}
            >{translate(`${translationPrefix}.actionText`)}</CallToActionButton>
          </CardActions>
        : <></>
      }
      {editDialog}
    </ContentWrapperCard>
  )
}

export interface EditDialogProps {
  open: boolean;
  onClose: () => void;
  onEditSave: (formData: Record<string, any>) => Promise<any> | void;
  title: string;
  editRecord?: RaRecord;
  editForm?: JSX.Element | null;
  onEditCancel?: () => void;
  saveButtonTitle?: string;
}

export const EditDialog: React.FC<EditDialogProps> = ({
  open,
  onClose,
  onEditSave,
  title,
  editRecord,
  editForm,
  onEditCancel = () => { },
  saveButtonTitle = 'Save',
}) => {
  const handleCancel = async () => {
    if (onEditCancel) await onEditCancel();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Form
        record={editRecord}
        onSubmit={async (formData) => {
          await onEditSave(formData);
          onClose();
        }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{editForm}</DialogContent>
        <DialogActions style={{ marginRight: '2em', marginBottom: '1em' }}>
          <Button variant="outlined" size="small" color="primary" onClick={handleCancel}>
            Cancel
          </Button>
          <SaveButton
            label=""
            variant="contained"
            size="small"
            color="primary"
            icon={<span>{saveButtonTitle}</span>}
          />
        </DialogActions>
      </Form>
    </Dialog>
  );
};

