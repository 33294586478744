import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material"

import { Button } from "react-admin"
import { useState } from "react"
import FilePreview from "../files/FilePreview"
import { isNil } from "../../../util/ScaleUtils"
import CustomFileSelector from "../../../components/CustomFileSelector"

type FileUploadDialogProps = {
  onCancel: (args?: any) => unknown
  onUpload: (file: File) => unknown
  title: string | JSX.Element
  visible: boolean
}

const FileUploadDialog = (props: FileUploadDialogProps) => {
  const [selectedFile, setSelectedFile] = useState<File>()

  const onCancelClick = () => {
    setSelectedFile(undefined)
    props.onCancel()
  }

  return (
    <Dialog open={props.visible} onClose={props.onCancel}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent dividers={true}>
        <center>
          <FilePreview file={selectedFile} />
          <CustomFileSelector
            text='Browse files'
            size='small'
            variant='contained'
            accept={'image/*'}
            onFileSelect={setSelectedFile}
          />
        </center>
      </DialogContent>
      <DialogActions>
        <Button
          label='Cancel'
          style={{ marginBottom: '8px' }}
          variant='outlined'
          onClick={onCancelClick}
        />
        <Button
          label='Upload'
          style={{ marginBottom: '8px' }}
          variant='contained'
          disabled={isNil(selectedFile)}
          onClick={() => {
            if (selectedFile) {
              props.onUpload(selectedFile)
            }
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export default FileUploadDialog
