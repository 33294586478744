import Chip from '@mui/material/Chip'
import Grid from "@mui/material/Grid"
import { ReactNode, useEffect, useState } from "react"
import {
  AutocompleteArrayInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  email,
  required,
  useGetList,
  useTranslate,
} from "react-admin"
import ProjectFiles from './ProjectFiles'
import ProjectStatusChip from "./ProjectStatusChip"
import { BusinessExtended } from "../../model/ScaleTypes"

const TAG_STYLE = {
  borderRadius: '4px',
  backgroundColor: "#DDB1FF",
  marginRight: "4px",
  fontSize: '12px',
  lineHeight: '15px',
  height: '20px'
}

const GridRow = ({ xs = 12, children }: { xs?: number, children: ReactNode | ReactNode[] }) => {
  const nodes = Array.isArray(children) ? children : [children]
  const cols = nodes.length
  const colSpan = Math.floor(xs / cols)
  
  return <>
  {
    nodes.map(node => {
      return (
        <Grid item xs={colSpan} key={nodes.indexOf(node)}>
          { node }
        </Grid>
      )
    })
  }
  </>
}

const Field = ({ label, children }: { label: string, children?: JSX.Element | JSX.Element[] }) => (
  <div style={{
    alignItems: 'center',
    position: 'relative',
    padding: '16px 8px 16px 8px',
    borderRadius: '8px',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 400,
    color: '#141414',
  }}>

    <label className='scale-label'>{label}</label>
    {children}
  </div>
)

const Text = ({ label, value = '' }: { label: string, value?: JSX.Element | string | number }) => (
  <Field label={label}>
    <div style={{
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      minHeight: '1lh'
    }}>
      {value}
    </div>
  </Field>
)

const StatusOptionRenderer = (choice: any): React.ReactNode =>
  <ProjectStatusChip status={choice.id} />

type ProjectBasicInfoProps = {
  project: BusinessExtended,
  editing?: boolean,
}

const ProjectBasicInfo = ({ project, editing = false }: ProjectBasicInfoProps) => {
  const translate = useTranslate()
  const horizonChoices = [
    { id: '1', name: 'Horizon 1: ' + translate('pos.definitions.h1definition') },
    { id: '2', name: 'Horizon 2: ' + translate('pos.definitions.h2definition') },
    { id: '3', name: 'Horizon 3: ' + translate('pos.definitions.h3definition') },
  ]
  const horizonText = project.horizon
    ? `Horizon ${project.horizon}: ` + translate(`pos.definitions.h${project.horizon}definition`)
    : ''

  const [tagChoices, setTagChoices] = useState<{ id: string, name: string }[]>([])
  
  const { data: tags } = useGetList("api_tagList");
  
  useEffect(() => {
    if (!editing || !tags) return;
    const tagNames = Array.from(new Set(tags.map(tag => tag.name))).sort();
    setTagChoices(tagNames.map(tag => ({ id: tag, name: tag })));
  }, [editing, tags]);
  
  return (
    <Grid container width="100%">
      <GridRow>
        { editing
            ? <TextInput label='Project name' source='name' fullWidth validate={required()} />
            : <Text label='Project name' value={project.name} />
        }
      </GridRow>

      <GridRow>
        { editing
            ? <TextInput label='Description' source='description' fullWidth multiline />
            : <Text label='Description' value={project.description} />
        }
      </GridRow>

      <GridRow>
        { editing
            ? <SelectInput label='Horizon' source='horizon' fullWidth choices={horizonChoices} />
            : <Text label='Horizon' value={horizonText} />
        }
        { editing
            ? <ReferenceInput label='Status' source='status' sort={{ field: 'id', order: 'ASC' }} reference='scale_status'>
                <SelectInput validate={required()} optionText={StatusOptionRenderer} fullWidth />
              </ReferenceInput>
            : <Text label='Status' value={<ProjectStatusChip status={project.status} />} />
        }
      </GridRow>

      <GridRow>
        { editing
            ? <TextInput label='Lead Person Email' source='leadPersonEmail' type='email' fullWidth validate={email()} />
            : <Text label='Lead Person Email' value={project.leadPersonEmail} />
        }
        { editing
            ? <ReferenceInput source='organizationUnitId' reference='scale_organizationUnit' sort={{ field: 'name', order: 'ASC' }}>
                <SelectInput fullWidth optionText='name' validate={required()} />
              </ReferenceInput>
            : <Text label='Organization Unit' value={project.organizationUnitName} />
        }
      </GridRow>

      <GridRow>
        { editing
            ? <AutocompleteArrayInput
                label='Themes'
                source='tags'
                fullWidth
                onCreate={(val) => {
                  if (!val) return;
                  const newOption = { id: val, name: val };
                  setTagChoices(currentChoices => [...currentChoices, newOption]);
                  return newOption;
                }}
                choices={tagChoices}
                optionText='name'
                optionValue='id'
                freeSolo
                sx={{
                  ['& .MuiChip-root']: TAG_STYLE,
                }}
              />
            : <Field label='Themes'>
                {project.tags?.map((tag, idx) => <Chip size='small' sx={TAG_STYLE} key={idx} label={tag} />)}
              </Field>
        }
        { !editing &&
          <ProjectFiles projectId={project.id} />
        }
      </GridRow>
    </Grid>
  )
}

export default ProjectBasicInfo
