import { Box, Grid, Typography } from "@mui/material"
import { ScaleButton } from "./ScaleButtons"
import { useRedirect } from "react-admin"
import { Business } from "../model/ScaleTypes"
import { Row, SPAN_MULTIPLIER } from "../pages/ProjectDashboard"

const DASHBOARD_BUTTON_SX = {
  fontColor: "white",
  backgroundColor: "#253241",
  borderTopLeftRadius: "8px",
  borderBottomLeftRadius: "8px",
  borderTopRightRadius: "0px",
  borderBottomRightRadius: "0px",
}

const MATURITY_BUTTON_SX = {
  color: "#253241",
  backgroundColor: "white",
  borderTopLeftRadius: "0px",
  borderBottomLeftRadius: "0px",
  borderTopRightRadius: "8px",
  borderBottomRightRadius: "8px"
}

export const DashboardTopRow: React.FC<{ project: Business, customItems?: JSX.Element | null, current: 'dashboard' | 'maturity' }> = ({
  project,
  current,
  customItems }) => {
  const redirect = useRedirect();

  const redirectToMaturity = () => { redirect(`/businessMaturity/${project.id}`); }
  const redirectToDashboard = () => { redirect(`/api_business/${project.id}/show`); }

  return <Row>
    <Grid item xs={1 * SPAN_MULTIPLIER} alignContent="center">
      <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
        <ScaleButton
          onClick={redirectToDashboard}
          variant="contained"
          sx={{
            ...DASHBOARD_BUTTON_SX,
            '&:hover': {
              ...(current === 'dashboard' ? DASHBOARD_BUTTON_SX : {})
            }
          }}
        >Project Dashboard</ScaleButton>
        <ScaleButton
          onClick={redirectToMaturity}
          disabled={!project || !project.id}
          variant="contained"
          sx={{
            ...MATURITY_BUTTON_SX,
            '&:hover': {
              ...(current === 'maturity' ? MATURITY_BUTTON_SX : {})
            }
          }}
        >Maturity Assessment</ScaleButton>
      </Box>
    </Grid>
    <Grid item xs={2 * SPAN_MULTIPLIER} display="flex" justifyContent="center">
      <Typography fontWeight={700} fontSize={24} color="#253241">
        {project.name ?? 'New Project'}
      </Typography>
    </Grid>
    <Grid item xs={1 * SPAN_MULTIPLIER} alignContent="center">
      <Box display="flex" justifyContent='flex-end'>
        {customItems}
      </Box>
    </Grid>
  </Row>
}