import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TablePaginationProps,
  TableRow,
  TableSortLabel
} from '@mui/material';
import React, { useState, ReactNode } from 'react';
import { useTranslate } from 'react-admin';
import { CARD_LIGHT } from '../Colors';

function getSorting<T>(order: 'asc' | 'desc', orderBy: keyof T): (a: T, b: T) => number {
  return order === 'asc'
    ? (a, b) => compare(a[orderBy], b[orderBy])
    : (a, b) => -compare(a[orderBy], b[orderBy]);
}

function compare(a: any, b: any): number {
  if (!a) return -1;
  if (!b) return 1;
  if (typeof a === 'number' && typeof b === 'number') {
    return a - b;
  } else if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b);
  } else {
    return 0;
  }
}

export type Column<T> = {
  id: keyof T;
  header: string | ReactNode;
  style?: React.CSSProperties;

  /**
   * Formatter used to render the content of the table cell
   * @param value the specific item that is the value in that specific row
   * @param rowObject the object that represents the row, from where the value is gotten
   * @returns ReactNode that represents the rendered value
   */
  format?: (value: any, rowObject: T) => ReactNode;
  disableSorting?: boolean
  width?: string;
};

type MuiTablePaginationProps = Pick<TablePaginationProps, 'labelRowsPerPage' | 'sx'>

type SortableTableProps<T> = MuiTablePaginationProps & {
  columns: Column<T>[];
  data: T[];
  defaultSortColumn?: keyof T;
  defaultSortDirection?: 'asc' | 'desc';
  initialRowsPerPage?: number;
  /**
   * Formatter used to give style each row for the table.
   * @param rowObject the data object for the row
   * @param rowIndex index of the row in the table
   * @returns CSS styles for the row
   */
  rowFormatter?: (rowObject: T, rowIndex?: number) => React.CSSProperties;
  disablePageSizeChange?: boolean;
  disableHeader?: boolean;
};

function SortableTable<T>({
  columns,
  data,
  defaultSortColumn,
  defaultSortDirection,
  rowFormatter,
  initialRowsPerPage = undefined,
  labelRowsPerPage,
  disablePageSizeChange = false,
  disableHeader = false,
  sx,
}: SortableTableProps<T> & { rowsPerPage?: number }) {
  const translate = useTranslate();
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>(defaultSortDirection || 'desc');
  const [orderBy, setOrderBy] = useState<keyof T | null>(defaultSortColumn || null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);

  const handleSortRequest = (property: keyof T) => {
    const isAsc = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedData = orderBy
    ? [...(data || [])].sort(getSorting(orderDirection, orderBy))
    : data;

  const paginatedData = rowsPerPage ? sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : sortedData;

  const makeColumnHeader = (column: Column<T>) => {
    if (column.disableSorting) {
      return typeof column.header === 'string'
        ? <strong>{translate(column.header, { _: column.header })}</strong>
        : column.header;
    }

    return (
      <TableSortLabel
        hideSortIcon={true}
        disabled={!!column.disableSorting}
        active={orderBy === column.id}
        direction={orderBy === column.id ? orderDirection : 'asc'}
        onClick={() => handleSortRequest(column.id)}
      >
        {typeof column.header === 'string' ? <strong>{translate(column.header, { _: column.header })}</strong> : column.header}
      </TableSortLabel>
    );
  };

  return (
    <Table size="small" sx={sx}>
      { !disableHeader &&
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell key={column.id as string}>
                {makeColumnHeader(column)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      }
      <TableBody>
        {paginatedData.map((row, index) => (
          <TableRow key={index} style={rowFormatter ? rowFormatter(row, index) : undefined}>
            {columns.map(column => (
              <TableCell key={column.id as string} width={column.width} style={column.style}>
                {/* @ts-ignore */}
                {column.format ? column.format(row[column.id], row) : row[column.id]}
              </TableCell>
            ))}
          </TableRow>
        ))}
        {paginatedData.length === 0 && (
          <TableRow key={0}>
            <TableCell style={{ textAlign: 'center', fontStyle: 'italic', backgroundColor: CARD_LIGHT }} colSpan={columns.length}>
              No data to show
            </TableCell>
          </TableRow>
        )}
      </TableBody>
      {rowsPerPage && <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={ disablePageSizeChange ? [] : [5, 10, 25] } // Can customize as needed
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={labelRowsPerPage}
          />
        </TableRow>
      </TableFooter>}
    </Table>
  );
}

export default SortableTable;