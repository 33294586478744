import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import DescriptionIcon from '@mui/icons-material/Description'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import { useState } from 'react'

import { useProjectFiles, useScaleDataProvider } from '../../state/provider/ScaleDataProvider'
import CustomFileSelector from '../CustomFileSelector'
import { ScaleButton } from '../ScaleButtons'
import { ProjectFile } from '../../model/ScaleTypes'
import { isNotNil } from '../../util/ScaleUtils'
import SortableTable, { Column } from '../common/SortableTable'

const FILE_UPLOAD_LIMIT_MB = 10
const FILE_UPLOAD_LIMIT_BYTES = FILE_UPLOAD_LIMIT_MB * 1024 * 1024

const marginTop =  '1em'
const marginRight = '1em'
const marginBottom = '1em'

const fontBold = {
  fontFamily: 'Roboto Slab',
  fontWeight: 700,
}

const flexCenter = {
  display: 'flex',
  alignItems: 'center',
}

const flexAlignRight = {
  marginRight: 0,
  marginLeft: 'auto',
}

const verticalMargins = {
  marginTop,
  marginBottom,
}

const dialogTitleStyle = {
  ...flexCenter,
  flexDirection: 'row',
  ...fontBold,
  fontSize: '24px',
}

const rightAlignedIconStyle = {
  ...flexAlignRight,
  fontSize: '24px',
}

const closeIconTextStyle = {
  ...rightAlignedIconStyle,
  ['& :hover']: {
    cursor: 'pointer',
  }
}

const uploadAreaStyle = {
  ...flexCenter,
  minWidth: '590px',
  minHeight: '250px',
  borderRadius: '12px',
  border: '2px dashed #e9e9e9',
  backgroundColor: '#f9f9f9',
  flexDirection: 'column',
  justifyContent: 'center',
}

const fileTypesTextStyle = {
  fontSize: '12px',
  color: '#828282',
  lineHeight: '16px',
  marginTop: '8px',
}

const fileWrapperStyle = {
  ...flexCenter,
  border: '1px solid #c9c9c9',
  borderRadius: '4px',
  marginTop: '8px',
  padding: '8px',
  [':hover']: {
    backgroundColor: '#f9f9f9',
  }
}

const addedFileNameStyle = {
  color: '#141414',
  fontSize: '14px',
  fontStyle: 'italic',
  lineHeight: '20px',
  marginLeft: '8px',
}

const savedFileNameStyle = {
  ...addedFileNameStyle,
  ...fontBold,
  fontStyle: 'normal',
}

const fileIconColor = '#eb5764'

const RightAlignedCloseIcon = ({ onClick }: { onClick?: () => void }) => (
  <Typography sx={closeIconTextStyle} onClick={onClick}>
    <CloseIcon sx={{ display: 'flex', }} />
  </Typography>
)

const UploadedFileRow = (f: ProjectFile) => (
  <a
    href={f.url}
    target='_blank'
    style={{ textDecoration: 'none' }}>
    <Box sx={fileWrapperStyle}>
      <DescriptionIcon sx={{ color: fileIconColor, }} />
      <Typography sx={savedFileNameStyle}>{f.name}</Typography>
    </Box>
  </a>
)

const uploadedFilesColumns: Column<ProjectFile>[] = [
  {
    id: 'name',
    header: '',
    disableSorting: true,
    format: (_: any, f: ProjectFile) => <UploadedFileRow {...f} />,
    style: {
      border: 0,
      padding: 0,
    }
  }
]

const FileListing = ({ files, loading }: { files: ProjectFile[], loading: boolean }) => (
  <>
    <Box sx={{ ...flexCenter, ...verticalMargins, }}>
      <b>Uploaded files</b>
      { loading && <CircularProgress size={18} sx={{ marginLeft: '1em', }} /> }
    </Box>

    { files.length > 0
      ? <SortableTable
          defaultSortColumn={undefined}
          columns={uploadedFilesColumns}
          data={files}
          initialRowsPerPage={5}
          labelRowsPerPage=''
          disablePageSizeChange={true}
          disableHeader={true}
          sx={{
            '.MuiTableCell-footer': {
              border: 0,
            },
            '.MuiTablePagination-toolbar': {
              minHeight: 'unset',

              '.MuiTablePagination-displayedRows': {
                marginTop: 0,
                marginBottom: 0,
              },
            },
          }}
        />
      : loading
        ? null
        : <Box sx={{...verticalMargins, ...fileTypesTextStyle}}>No files have been added</Box>
    }
  </>
)

const ProjectFiles = ({ projectId }: {projectId: string}) => {
  const scaleDataProvider = useScaleDataProvider()
  const [showFileUploadDialog, setShowFileUploadDialog] = useState<boolean>(false)
  const openDialog = () => setShowFileUploadDialog(true)
  const closeDialog = () => setShowFileUploadDialog(false)

  const { files, loading, reload } = useProjectFiles({ projectId })
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false)
  const fileCount = files.length
  const btnLabel = 'Attach files' + (fileCount > 0 ? ` (${fileCount})` : '')

  const uploadFile = async (file?: File) => {
    if (isNotNil(file)) {
      if (file.size <= FILE_UPLOAD_LIMIT_BYTES) {
        setUploadInProgress(true)
        await scaleDataProvider.uploadFile({ projectId, file })
        setUploadInProgress(false)
        reload()
      } else {
        // TODO: file size is too large, show warning to user?
      }
    }
  }

  return (
    <div style={{ ...flexCenter, width: '100%', height: '100%' }}>
      <ScaleButton
        variant='outlined'
        onClick={openDialog}
        startIcon={<DescriptionOutlinedIcon />}
        sx={flexAlignRight}
      >{btnLabel}</ScaleButton>

      <Dialog
        open={showFileUploadDialog}
        onClose={closeDialog}
        maxWidth='lg'
        sx={{ padding: '1lh', }}
      >
        <DialogTitle sx={dialogTitleStyle}>
          Project Files
          <RightAlignedCloseIcon onClick={closeDialog} />
        </DialogTitle>

        <DialogContent>
          <Box sx={uploadAreaStyle}>
            <CloudUploadIcon sx={{ color: '#c9c9c9', fontSize: '100px', }} />

            <b style={{ margin: '1em' }}>
              { uploadInProgress
                ? 'Uploading...'
                : 'Select files to upload'
              }
            </b>

            { uploadInProgress
              ? <Box sx={{ ...flexCenter, margin: '1em', width: '80%', }}>
                  <LinearProgress sx={{ width: '100%', opacity: 0.7, }} />
                </Box>
              : <CustomFileSelector
                  disabled={uploadInProgress}
                  accept={'image/*,.doc,.docx,.pdf'}
                  multiple={false}
                  onFileSelect={uploadFile}
                  text='Browse files'
                  color='primary'
                  variant='contained'
                  sx={{ marginTop }}
                />
            }
          </Box>

          <Typography sx={fileTypesTextStyle}>
            { `Scale supports images, .doc, and .pdf files, maximum file size ${FILE_UPLOAD_LIMIT_MB}MB`}
          </Typography>

          <FileListing files={files} loading={loading} />
        </DialogContent>

        <DialogActions sx={{ marginRight, marginBottom, paddingTop: 0, }}>
          <ScaleButton
            color='secondary'
            variant='outlined'
            onClick={closeDialog}
            label='Close'
          />
          <ScaleButton
            color='primary'
            variant='contained'
            disabled={true}
            label='Save'
          />
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ProjectFiles
