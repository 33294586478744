import ActionDelete from '@mui/icons-material/Delete';
import DescriptionIcon from '@mui/icons-material/Description';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ReplayIcon from '@mui/icons-material/Replay';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField as MuiTextField,
  Typography,
  DialogContentText,
} from '@mui/material';
import {
  FC,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  SelectInput,
  useNotify,
  useRedirect,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { canCreateReport } from './CustomReportPage';
import { LoadingAnimation } from '../components/LoadingAnimation';
import { MaturityScoreCardContent } from '../components/MaturityScore';
import { DisplayCard, EditableWizardCard } from '../components/WizardCard';
import { MilestoneTable } from '../components/cards/PlanCard';
import { createBusinessHistoryGraphData } from '../components/charts/BusinessHistoryGraph';
import { createMaturityChartData } from '../components/charts/MaturityChart';
import { PlotlyChart } from '../components/charts/PlotlyCharts';
import {
  BusinessExtended,
  MaturityCategory,
  MaturityModel,
  MaturityScoreExtended,
  Plan,
  ScaleStatus,
} from '../model/ScaleTypes';
import BusinessGoalsForm from '../resources/scale_business/BusinessGoalsForm';
import { PlanFormContent, transformPlan } from '../resources/scale_plan/PlanForm';
import { useProjectDashboardData } from '../state/useProjectDashboardData';
import { useScaleDataProvider } from '../state/provider/ScaleDataProvider';
import { defaultCurrencyFormatter, formatDate } from '../util/FormattingUtils';
import { formatArrayForHasura, isNil, isNotNil } from '../util/ScaleUtils';
import ProjectBasicInfo from '../components/project/ProjectBasicInfo';
import { SummaryCoach } from './OpenAI';
import { CategoryChip } from '../components/CategoryChip';
import ButtonTooltip from '../components/ButtonTooltip';
import { DashboardTopRow } from '../components/DashboardTopRow';
import { ScaleButton } from '../components/ScaleButtons';

interface ProjectDashboardProps {
  projectId?: string
}

export function statusToColor(status: ScaleStatus | undefined): string {
  switch (status) {
    case 'active':
      return '#3BE256'

    case 'removed':
      return 'red'

    case 'on hold':
      return 'lightgray'

    case 'not tracked':
      return 'black'
  }

  return '#000000'
}

export function statusToDisplay(status: ScaleStatus | undefined) {
  return (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      <FiberManualRecordIcon
        fontSize="inherit"
        style={{ marginRight: 4, color: statusToColor(status) }}
      />
      {status}
    </span>
  )
}

export const TAG_STYLE = {
  borderRadius: '4px',
  backgroundColor: "#DDB1FF",
  marginRight: "4px",
  fontSize: '12px',
  lineHeight: '15px',
  height: '20px'
}

const GRID_XS = 12
const COLS = 4
export const SPAN_MULTIPLIER = Math.round(GRID_XS / COLS)

export const Row = ({ children }: { children: JSX.Element | JSX.Element[] }) => (
  <>{children}</>
)

const Field = ({ label, children }: { label: string, children?: JSX.Element | JSX.Element[] }) => (
  <div style={{
    alignItems: 'center',
    position: 'relative',
    padding: '16px 8px 16px 8px',
    borderRadius: '8px',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 400,
    color: '#141414',
  }}>

    <label className='scale-label'>{label}</label>
    {children}
  </div>
)

const Text = ({ label, value = '' }: { label: string, value?: JSX.Element | string | number }) => (
  <Field label={label}>
    <div style={{
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      minHeight: '1lh'
    }}>
      {value}
    </div>
  </Field>
)

export type ProjectProps = { project: BusinessExtended }

const BusinessGoalsShow = ({ project }: ProjectProps) => {
  return (
    <Grid container width="100%">
      <Row>
        <Grid item xs={6}>
          <Text label='Start Date' value={formatDate(project?.startDate)} />
        </Grid>

        <Grid item xs={6}>
          <Text label='Budget' value={defaultCurrencyFormatter(project?.budget ?? 0)} />
        </Grid>
      </Row>

      <Row>
        <Grid item xs={12}>
          <Text label='Business Model' value={project?.businessModel} />
        </Grid>
      </Row>

      <Row>
        <Grid item xs={12}>
          <Text label='Calculated Business Potential' value={defaultCurrencyFormatter(project?.businessPotential ?? 0)} />
        </Grid>
      </Row>
    </Grid>
  )
}

const coachDisclaimer = <span style={{ color: '#FF5F6D', margin: '0.2em' }}>*</span>

type PlanningSessionProps = {
  project: BusinessExtended
  maturityModel: MaturityModel
  setSelectedPlanningCategoryOrdinals: (ordinals: number[]) => any
}

const NewPlanningSessionForm = ({
  project,
  maturityModel,
  setSelectedPlanningCategoryOrdinals,
}: PlanningSessionProps) => {
  const translate = useTranslate();
  const scaleDataProvider = useScaleDataProvider()
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<Set<string>>(new Set<string>())
  const [recommendedCategoryIds, setRecommendedCategoryIds] = useState<Set<string>>(new Set<string>())
  const [numberOfRecommendations, setNumberOfRecommendations] = useState<number>(0)
  const { getValues } = useFormContext();

  const selectCategories = (categoryIds: Set<string>) => {
    const selectedOrdinals = maturityModel.categories
      .filter(cat => categoryIds.has(cat.id))
      .map(cat => cat.order)
    setSelectedCategoryIds(categoryIds)
    setSelectedPlanningCategoryOrdinals(selectedOrdinals)
  }

  const resetToRecommendedCategories = () => {
    selectCategories(recommendedCategoryIds)
  }

  const onlyRecommendedCategoriesSelected = (): boolean => {
    return selectedCategoryIds.size === recommendedCategoryIds.size
      && Array.from(selectedCategoryIds).every(v => recommendedCategoryIds.has(v))
  }

  useEffect(() => {
    if (!scaleDataProvider) return;
    (async () => {
      const hadOnlyRecommendedCategories = onlyRecommendedCategoriesSelected()
      const recommendedPrios = await scaleDataProvider.getSuggestedMaturityCategories({ projectId: project.id, amount: numberOfRecommendations })
      const recommendedIds = new Set(recommendedPrios.map(p => p.categoryId))
      setRecommendedCategoryIds(recommendedIds)

      if (hadOnlyRecommendedCategories || selectedCategoryIds.size === 0) {
        selectCategories(recommendedIds)
      }
    })()
  }, [scaleDataProvider, numberOfRecommendations])

  const toggleCategory = (category: MaturityCategory) => {
    const newSelections = new Set(selectedCategoryIds)
    if (newSelections.has(category.id)) {
      newSelections.delete(category.id)
    } else {
      newSelections.add(category.id)
    }

    selectCategories(newSelections)
  }

  function updateSelection() {
    const selectedMinutes = parseInt(getValues("allocatedTime") || '0', 10);
    const recommendationCount = Math.floor((selectedMinutes - 15) / 15)
    setNumberOfRecommendations(recommendationCount);
  }

  return (
    <Grid container width="100%">
      <Row>
        <Grid item xs={12}>
          <Typography color="#253241" fontSize={16}>{translate("assessmentsForm.instruction")}</Typography>
        </Grid>
      </Row>

      <Row>
        <Grid item xs={6}>
          <SelectInput
            label='assessmentsForm.allocatedTime'
            source='allocatedTime'
            fullWidth
            onChange={updateSelection}
            choices={[
              { id: '30', name: '30 min' },
              { id: '45', name: '45 min' },
              { id: '60', name: '60 min' },
              { id: '90', name: '90 min' },
              { id: '120', name: '120 min' },
            ]}
          />
        </Grid>
      </Row>

      <Row>
        <Grid item xs={12}>
          <Typography color="#253241" fontSize={16} style={{ display: 'flex', alignItems: 'center' }}>
            <img src='/scale-coach.png' />
            <span style={{ marginLeft: '8px', fontWeight: 700 }}>{translate("assessmentsForm.selectionTitle")}</span>
            {coachDisclaimer}
            <Button
              size='medium'
              color='primary'
              startIcon={<ReplayIcon style={{ transform: 'rotate3d(-0.5, 1, 0, 180deg)' }} />}
              label='assessmentForm.recreateLabel'
              disabled={onlyRecommendedCategoriesSelected()}
              onClick={resetToRecommendedCategories}
              style={{ marginRight: 0, marginLeft: 'auto', fontWeight: 'bold', textTransform: 'capitalize' }}
            />
          </Typography>
        </Grid>
      </Row>

      <Row>
        <Grid item xs={12}>
          <span>
            {
              maturityModel.categories.sort((a, b) => {
                return a.order - b.order
              }).map((cat, idx) => <CategoryChip isSelectable
                category={cat} isSelected={selectedCategoryIds.has(cat.id)}
                onClick={toggleCategory} onDelete={toggleCategory}
                sx={{
                  fontSize: '14px',
                  lineHeight: '14px',
                  margin: '8px',
                }}
              />)
            }
          </span>
        </Grid>
      </Row>

      <Row>
        <Grid item xs={12}>
          <Typography color="#253241" fontSize={14}>
            {coachDisclaimer}
            {translate('assessmentsForm.recommendationExplanation')}
          </Typography>
        </Grid>
      </Row>
    </Grid>
  )
}

interface DeleteProjectButtonProps {
  disabled?: boolean
  projectName?: string;
  onConfirmDelete: () => void;
}

const DeleteProjectButton: React.FC<DeleteProjectButtonProps> = ({ disabled = true, projectName, onConfirmDelete }) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [inputProjectName, setInputProjectName] = useState<string>('')
  const notify = useNotify()

  const showDialog = () => setShowConfirmDialog(true)

  const closeDialog = () => {
    setShowConfirmDialog(false)
    setInputProjectName('')
  }

  const projectNameMatches = () =>
    inputProjectName.trim().toLowerCase() === projectName?.trim().toLowerCase()

  const handleDelete = () => {
    if (projectNameMatches()) {
      closeDialog()
      onConfirmDelete()
    } else {
      notify('warnings.delete.project_name_not_matching', { type: 'warning' })
    }
  }

  return (
    <div>
      <ScaleButton
        variant='outlined'
        color='secondary'
        disabled={disabled}
        startIcon={<ActionDelete />}
        onClick={showDialog}
        sx={{ marginRight: "8px" }}
      >Delete Project</ScaleButton>

      <Dialog open={showConfirmDialog} onClose={closeDialog}>
        <DialogTitle>Delete project {projectName}?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To confirm the deletion of this project, please type the project
            name (<strong>{projectName}</strong>) below.
          </DialogContentText>
          <MuiTextField
            autoFocus
            margin='dense'
            label='Project Name'
            fullWidth
            value={inputProjectName}
            onChange={(e) => setInputProjectName(e.target.value)}
          />
        </DialogContent>
        <DialogActions sx={{ paddingBottom: '1lh' }}>
          <ScaleButton
            variant='outlined'
            color='primary'
            startIcon={<CancelIcon />}
            onClick={closeDialog}
            sx={{ marginRight: '8px' }}
          >No, Cancel</ScaleButton>
          <ScaleButton
            variant='contained'
            startIcon={<ActionDelete />}
            disabled={!projectNameMatches()}
            onClick={handleDelete}
            sx={{
              color: '#ffffff',
              backgroundColor: '#ff5f6d',
              marginRight: '16px',
              ':hover': {
                opacity: 0.8,
                color: '#ffffff',
                backgroundColor: '#ff5f6d',
              }
            }}
          >Yes, Delete</ScaleButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const calculateCurrentStep = (project: BusinessExtended, maturityScores: MaturityScoreExtended[]) => {
  if (![project.name, project.status, project.organizationUnitId].every(isNotNil)) {
    return 1;
  }

  if (![project.businessPotential].every(isNotNil)) {
    return 2;
  }

  if (!project.plans || !project.plans.length) {
    return 3;
  }

  if (maturityScores.length === 0) {
    return 4;
  }
  return 5;
};


const ProjectDashboard: FC<ProjectDashboardProps> = () => {
  const { id } = useParams()
  const redirect = useRedirect()
  const translate = useTranslate()
  const notify = useNotify()
  const refresh = useRefresh();
  const scaleDataProvider = useScaleDataProvider()
  const {
    project: loadedProject,
    maturityHistory,
    maturityModel,
    maturityScores,
  } = useProjectDashboardData(id)

  const [project, setProject] = useState<BusinessExtended>()
  const [isReportPossible, setIsReportPossible] = useState<boolean | undefined>(undefined);
  const [maturityChartType, setMaturityChartType] = useState<('bar' | 'radar')>('bar');
  const [planningCategoryOrdinals, setSelectedPlanningCategoryOrdinals] = useState<number[]>([])

  const createNewProjectIfProjectIdIsNil = () => {
    if (isNil(id)) {
      setProject({} as BusinessExtended)
    } else if (isNotNil(loadedProject)) {
      setProject(loadedProject)
    }
  }

  const checkIsProjectReportCreationPossible = () => {
    if (isReportPossible === undefined && isNotNil(project)) {
      canCreateReport(project).then(setIsReportPossible)
    }
  }

  useEffect(createNewProjectIfProjectIdIsNil, [id, loadedProject])
  useEffect(checkIsProjectReportCreationPossible, [project])

  const saveProject = async (formData: Record<string, any>) => {
    if (isNil(project)) return;
    if (formData.tags) formData.tags = formatArrayForHasura(formData.tags.sort())

    console.log('Saving project.id', project.id, 'edited fields:', formData)

    try {
      if (isNil(project.id)) {
        const createResult = await scaleDataProvider.create('scale_business', {
          data: formData,
        })
        notify(`${createResult.data.name} created successfully`)
        redirect('show', '/api_business', createResult.data.id)
      } else {
        const updateResult = await scaleDataProvider.update('scale_business', {
          id: project.id,
          data: {
            id: project.id,
            ...formData,
          },
          previousData: {
            ...project
          }
        })
        const updatedProject = await scaleDataProvider.getOne<BusinessExtended>('api_business', { id: project.id })
        notify('Changes saved successfully')
        setProject(updatedProject.data)
      }
    } catch (error: any) {
      notify(`Error: ${error.message}`, { type: 'warning' })
    }
  }

  const markProjectDeleted = async (projectId?: string) => {
    if (isNotNil(projectId)) {
      try {
        const succeeded = await scaleDataProvider.project.delete({ projectId })
        if (succeeded) {
          notify('generic.deleted', { type: "success", messageArgs: { itemType: "Project" } });
          redirect('/businessList');
        }
      } catch (error: any) {
        notify(`Error: ${error.message}`);
      }
    }
  }

  // This just handles creating a plan, update should be checked also
  const savePlan = async (formData: Record<string, any>) => {
    if (isNil(project)) return;
    console.log("Saving plan", formData);
    try {
      const createResult = await scaleDataProvider.create('scale_plan', {
        data: {
          ...transformPlan(formData as Plan),
          businessId: project.id
        },
      })
      notify(`${createResult.data.name} created successfully`)
      // for some reason I need to create this manually as refresh() nor redirect() seem to work.
      project?.plans?.push(createResult.data as Plan);
      refresh();
    } catch (error: any) {
      notify(`Error: ${error.message}`, { type: 'warning' })
    }
  }

  if (isNil(project)) {
    return <LoadingAnimation loadingText={translate('generic.loading', { content: 'business' })} />
  }

  const plans: Plan[] = project?.plans ?? [];
  const maturityChartParams = isNotNil(maturityModel) && isNotNil(maturityScores)
    ? createMaturityChartData({ maturityModel, scores: maturityScores, plans, type: maturityChartType })
    : null
  const maturityHistoryGraphParams = isNotNil(maturityModel) && isNotNil(maturityHistory)
    ? createBusinessHistoryGraphData({
      maturityModel,
      data: maturityHistory,
      countDaysFromStart: false,
      plans,
      // estimatedLaunchDate is missing as it needs to be fetched from the record.
    })
    : null

  const currentStep = calculateCurrentStep(project, maturityScores);

  const openPlanningSession = () => {
    redirect(`/businessMaturity/${id}?focusCategories=${planningCategoryOrdinals.join(',')}`)
  }

  const redirectToMaturity = () => { redirect(`/businessMaturity/${id}`); }
  const redirectToReport = () => { redirect(`/businessReport/${id}`); }

  return (
    <Grid container sx={{ maxWidth: 'calc(100vw - 50px)' }}>
      <DashboardTopRow project={project} current='dashboard'
        customItems={<>
          <DeleteProjectButton
            disabled={isNil(project.id)}
            projectName={project.name}
            onConfirmDelete={() => markProjectDeleted(project.id)}
          />
          <ButtonTooltip
            title={translate(isReportPossible
              ? 'actions.open_report'
              : 'warnings.project.report.template_missing')
            }
          >
            <ScaleButton
              variant='outlined'
              color='primary'
              disabled={!isReportPossible}
              startIcon={<DescriptionIcon />}
              onClick={() => redirectToReport()}
            >{translate('actions.open_report')}</ScaleButton>
          </ButtonTooltip>
        </>} />

      <Row>
        <Grid item xs={2 * SPAN_MULTIPLIER}>
          <EditableWizardCard
            translationPrefix='projectDashboard.projectInformation'
            step={1}
            currentStep={currentStep}
            displayContent={<ProjectBasicInfo project={project} />}
            editForm={<ProjectBasicInfo project={project} editing={true} />}
            editRecord={project}
            onEditSave={saveProject}
          />
        </Grid>

        <Grid item xs={1 * SPAN_MULTIPLIER}>
          <EditableWizardCard
            translationPrefix='projectDashboard.businessGoals'
            step={2}
            currentStep={currentStep}
            displayContent={<BusinessGoalsShow project={project} />}
            editForm={<BusinessGoalsForm />}
            editRecord={project}
            onEditSave={saveProject}
          />
        </Grid>

        <Grid item xs={1 * SPAN_MULTIPLIER}>
          <DisplayCard
            translationPrefix='projectDashboard.maturityScore'
            active={isNotNil(maturityHistory) && maturityHistory.length > 0}
          >
            {maturityHistory && maturityModel &&
              <MaturityScoreCardContent data={maturityHistory} maturityModel={maturityModel} onClick={redirectToMaturity} />
            }
          </DisplayCard>
        </Grid>
      </Row>

      <Row>
        <Grid item xs={2 * SPAN_MULTIPLIER}>
          <EditableWizardCard
            translationPrefix='projectDashboard.milestones'
            step={3}
            currentStep={currentStep}
            displayContent={project && maturityModel && maturityScores &&
              <MilestoneTable
                maturityModel={maturityModel}
                maturityScoreHistory={maturityScores}
                plans={plans}
              />
            }
            editForm={
              <>
                <PlanFormContent />
              </>}
            onEditSave={savePlan}
          />
        </Grid>

        <Grid item xs={2 * SPAN_MULTIPLIER}>
          <EditableWizardCard
            translationPrefix='projectDashboard.planningSessions'
            step={4}
            currentStep={currentStep}
            displayContent={<SummaryCoach business={project} scoresWithNotes={maturityScores} />}
            editRecord={project}
            editForm={maturityModel &&
              <NewPlanningSessionForm
                project={project}
                maturityModel={maturityModel}
                setSelectedPlanningCategoryOrdinals={setSelectedPlanningCategoryOrdinals} />
            }
            saveButtonTitle='Start session'
            onEditSave={openPlanningSession}
          />
        </Grid>
      </Row>

      <Row>
        <Grid item xs={2 * SPAN_MULTIPLIER}>
          <DisplayCard
            translationPrefix='projectDashboard.currentMaturity'
            active={isNotNil(maturityScores) && maturityScores.length > 0}
          >
            {maturityChartParams &&
              <>
                <PlotlyChart params={maturityChartParams} />
                <Button
                  variant='outlined'
                  label={maturityChartType === 'bar' ? 'Show radar chart' : 'Show bar chart'}
                  onClick={() => setMaturityChartType(maturityChartType === 'bar' ? 'radar' : 'bar')}
                />
              </>
            }
          </DisplayCard>
        </Grid>

        <Grid item xs={2 * SPAN_MULTIPLIER}>
          <DisplayCard
            translationPrefix='projectDashboard.maturityHistory'
            active={isNotNil(maturityHistory) && maturityHistory.length > 0}
          >
            {maturityHistoryGraphParams &&
              <PlotlyChart params={maturityHistoryGraphParams} />
            }
          </DisplayCard>
        </Grid>
      </Row>
    </Grid >
  )
}

export default ProjectDashboard
