import MuiButton, { ButtonProps } from '@mui/material/Button'
import { styled } from '@mui/material/styles'

type ScaleButtonProps = ButtonProps & {
  label?: string,
}

export type { ScaleButtonProps }

const extendMuiButton = styled(MuiButton)<ScaleButtonProps>

const StyledButton = extendMuiButton({
  borderRadius: '14px',
  fontSize: '12px',
  fontWeight: 'bold',
  textTransform: 'capitalize',
})

export const ScaleButton = (props: ScaleButtonProps) => {
  return props.label
    ? <StyledButton {...props}>{props.label}</StyledButton>
    : <StyledButton {...props} />
}

const extendScaleButton = styled(ScaleButton)<ScaleButtonProps>

export const CallToActionButton = extendScaleButton({
  alignSelf: 'flex-end',
  backgroundColor: '#FF5F6D',
  borderRadius: '22px',
  fontSize: '16px',
})
